import React, { useContext, useMemo } from 'react';
import { Tag } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';

import routes from 'constants/routes';
import { QUERY_LIST_LOCATION_SIMPLE } from 'queries/LocationQueries';
import {
  QUERY_MEMBERSHIPS,
  QUERY_PAYMENT_PROCESSORS,
} from 'queries/MembershipLevel';
import {
  MUTATION_USER_CANCEL_SUBSCRIPTION,
  QUERY_LIST_USERS,
} from 'queries/UserQueries';
import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import { UserContext } from '../../../contexts/UserContext';
import { QUERY_LIST_CORPORATE_CLIENTS } from '../../../queries/DictionariesQueries';
import { FIELDS_NAMES } from '../../../components/Tables/const';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const Index = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const { mounted, setTableRef, refetch, getColumnSearchProps } = useTable();
  const {
    data: locations,
    error: locationsError,
    loading: locationsLoading,
  } = useQuery(QUERY_LIST_LOCATION_SIMPLE);
  const {
    data: memberships,
    error: membershipsError,
    loading: membershipsLoading,
  } = useQuery(QUERY_MEMBERSHIPS);
  const { data: paymentProcessors, loading: paymentProcessorsLoading } =
    useQuery(QUERY_PAYMENT_PROCESSORS);
  const [cancelSubscription] = useMutation(MUTATION_USER_CANCEL_SUBSCRIPTION);

  const {
    data: corpClients,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });

  const columns = useMemo(() => {
    return [
      {
        title: 'Full Name',
        key: 'first_name',
        dataIndex: 'first_name',
        render: (_: any, record: any) => (
          <Link to={routes.USERS + '/' + record.user_id}>
            {record.full_name}
          </Link>
        ),
        sorter: true,
        width: 130,
        filterOperation: 'fullNameContains',
        ...(mounted && getColumnSearchProps('Full Name')),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        width: 240,
        filterOperation: 'contains',
        ...(mounted && getColumnSearchProps('by Email')),
      },
      {
        title: 'Locations',
        dataIndex: 'locationRoles',
        key: 'locationRoles',
        render: (locationRoles: any[]) => {
          const activeLocationRoles = locationRoles.filter(
            (r) => !r.disabled_on,
          );
          return (
            <span>
              {!activeLocationRoles?.length && ' No Locations'}

              {activeLocationRoles?.map((lr: any) => (
                <Tag color="geekblue" key={lr.location_role_id}>
                  {lr.location ? (
                    lr.location.pretty_name || lr.location.name
                  ) : (
                    <b>All</b>
                  )}{' '}
                  / {lr.role.name}
                </Tag>
              ))}
            </span>
          );
        },
        width: 400,
        filterSubField: ['location_id'],
        filters:
          locations && !locationsError
            ? locations.listLocation.list.map((l: any) => ({
                value: l.location_id,
                text: l.name,
              }))
            : [],
      },
      {
        title: 'Current Membership',
        dataIndex: 'current_client_level.level_id',
        render: (_: any, record: any) => {
          return record.current_client_level
            ? record.current_client_level?.membershipLevel?.name
            : null;
        },
        sorter: true,
        width: 140,
        filters:
          memberships && !membershipsError
            ? memberships.listMembershipLevel.list.map((s: any) => ({
                value: s.level_id,
                text: s.name,
              }))
            : [],
      },
      {
        title: 'Account Type',
        dataIndex: 'current_client_level.level_id',
        render: (_: any, record: any) => {
          return record.current_client_level
            ? record.current_client_level?.clientRole?.name
            : null;
        },
        width: 140,
      },
      {
        title: 'Title',
        dataIndex: 'user_membership.more_info',
        render: (_: any, record: any) => {
          const membership = record.user_membership.find((m: any) => !m.to);
          const more_info =
            membership?.more_info && JSON.parse(membership.more_info);
          return more_info?.title;
        },
        width: 140,
      },
      {
        title: 'Graduation Year',
        dataIndex: 'user_membership.more_info',
        render: (_: any, record: any) => {
          const membership = record.user_membership.find((m: any) => !m.to);
          const more_info =
            membership?.more_info && JSON.parse(membership.more_info);
          return more_info?.graduateYear;
        },
        width: 140,
      },
      {
        title: 'Community',
        dataIndex: ['current_client_level', 'corporate_client_id'],
        key: 'current_client_level.corporate_client_id',
        render: (_: any, record: any) => {
          return record.current_client_level?.corpClient
            ? record.current_client_level?.corpClient?.name
            : record.current_client_level
            ? 'DEFAULT'
            : null;
        },
        sorter: true,
        width: 140,
        filters:
          corpClients && !corpClientsError
            ? [
                ...corpClients.listCorporateClient.map((s: any) => ({
                  value: s.corporate_client_id,
                  text: s.name,
                })),
                ...(dbUser?.isCorporateAdmin
                  ? []
                  : [
                      {
                        value: null,
                        text: 'DEFAULT',
                      },
                    ]),
              ]
            : [],
      },
      {
        title: 'Payment Type',
        dataIndex: 'payment_type',
        sorter: true,
        render: (paymentType?: number) => {
          return paymentProcessors?.listPaymentProcessor.find(
            (p: any) => p.processor_id === paymentType,
          )?.name;
        },
        width: 100,
      },
      {
        title: 'Promo Code',
        dataIndex: 'payment_promo_code',
        sorter: true,
        width: 100,
        filterOperation: 'contains',
        ...(mounted && getColumnSearchProps('by Promo Code')),
      },
      {
        title: 'Admin Permissions',
        dataIndex: 'permissions',
        key: 'permissions',
        render: (permissions: any[]) => (
          <span>
            {!permissions?.length && ' No Permissions'}

            {permissions?.map((perm: any) => (
              <Tag color="geekblue" key={perm.permission_id}>
                {perm.name}
              </Tag>
            ))}
          </span>
        ),
        filterOperation: 'isNull',
        filterSubField: ['permission_id'],
        filters: [
          {
            value: true,
            text: 'User',
          },
          {
            value: false,
            text: 'Admin',
          },
        ],
      },
      {
        title: FIELDS_NAMES.CORP_CLIENT,
        dataIndex: 'userCorporateClients',
        key: 'userCorporateClients.corporate_client_id',
        filters:
          corpClients && !corpClientsError
            ? [
                ...corpClients.listCorporateClient.map((l: any) => ({
                  value: l.corporate_client_id,
                  text: l.name,
                })),
                ...(dbUser?.isCorporateAdmin
                  ? []
                  : [
                      {
                        value: null,
                        text: 'NOT ASSIGNED',
                      },
                    ]),
              ]
            : [],
        width: 180,
        render: (userCorporateClients: any[]) => (
          <span>
            {!userCorporateClients?.length && ' No Corporate Clients'}

            {userCorporateClients?.map((client: any) => (
              <Tag color="geekblue" key={client.corporate_client_id}>
                {client.corporateClient.name}
              </Tag>
            ))}
          </span>
        ),
      },
    ];
  }, [
    paymentProcessors,
    memberships,
    membershipsError,
    locations,
    locationsError,
    mounted,
  ]);

  const actions = [
    {
      label: 'Create Location Role',
      type: 'primary',
      onlyOne: true,
      run: async ([record]: any[]) => {
        navigate(routes.LOCATIONS + '/role/create', {
          state: {
            user: record,
            backTo: routes.USERS,
          },
        });
      },
    },
    {
      label: 'Cancel Subscription',
      type: 'danger',
      onlyOne: true,
      run: async ([record]: any[]) => {
        await cancelSubscription({
          variables: {
            user_id: record.user_id,
          },
          refetchQueries: [{ query: QUERY_LIST_USERS }],
        });

        refetch();
      },
    },
  ];
  if (
    locationsLoading ||
    corpClientsLoading ||
    membershipsLoading ||
    paymentProcessorsLoading
  ) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ManageUsers) {
    return <PermissionsAlert />;
  }

  return (
    <CCCTable
      ref={setTableRef}
      rowKey="user_id"
      query={QUERY_LIST_USERS}
      queryKey="listUser"
      columns={columns}
      actions={actions}
    />
  );
};

export default Index;
